<template>
    <div class="improve-information-dialog">
      <base-dialog ref="registerValidDialog" style="width: 480px;" class="improve-dialog" @determine="improveDialogSubmit" title="企业已被注册" @cancel="improveDialogCancel" @close="improveDialogClose" determineText="账号申诉">
        <div class="body">
          <div style=" margin-bottom:20px;text-align: center;">
            <i class="el-icon-warning"></i>
          </div>
          <p>
            您申请注册的企业
            <span>{{ unitName }}</span>
            已被手机尾号
            <span>{{ endNumber }}</span>
            的用户注册，请确认。
          </p>
          <p>
            若确认被他人注册，您可以在该页面或登录页面点击
            <span>账号申诉</span>
            进行账号找回。
          </p>
        </div>
      </base-dialog>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ImproveInformationDialog',
    components: {},
    props: {
      unitName: String,
      endNumber: String,
    },
    data() {
      return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      /**
       * 打开弹框
       */
      openDialog() {
        this.$refs.registerValidDialog.show(true)
      },
      /**
       * 弹框取消
       */
      improveDialogCancel() {
        this.$refs.registerValidDialog.show(false)
      },
  
      /**
       * 弹框关闭
       */
      improveDialogClose() {},
  
      /**
       * 弹框确定
       */
      improveDialogSubmit() {
        this.$refs.registerValidDialog.show(false)
        this.$router.push({ name: 'AccountAppeal' })
      },
    },
  }
  </script>
  
  <style scoped lang="less">
  .flex-row {
    display: flex;
    align-items: center;
  }
  .body {
    padding: 20px;
    i {
      font-size: 50px;
      color: rgb(255, 147, 0);
    }
    p {
      span {
        color: rgb(83, 127, 252);
        // cursor: pointer;
      }
    }
  }
  .improve-dialog {
    /deep/ .el-dialog {
      width: 480px !important;
      min-width: 480px !important;
    }
  }
  </style>
  
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "zwx-dialog",
      attrs: {
        title: "封面选择",
        visible: _vm.visible,
        "close-on-click-modal": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        close: function($event) {
          return _vm.close()
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("自定义封面")]),
          !_vm.path
            ? _c(
                "sb-cover-upload",
                {
                  attrs: { "upload-folder": _vm.uploadFolder },
                  model: {
                    value: _vm.path,
                    callback: function($$v) {
                      _vm.path = $$v
                    },
                    expression: "path"
                  }
                },
                [
                  _c("div", { staticClass: "image-uploader large" }, [
                    _c("div", { staticClass: "uploader-btn" }, [
                      _c("img", {
                        staticClass: "image",
                        attrs: { src: require("./upload_icon.png") }
                      }),
                      _c("div", { staticClass: "right" }, [
                        _c("div", { staticClass: "uploader-text" }, [
                          _c("a", [_vm._v("点击此处")]),
                          _c("span", [_vm._v("或将文件拖至框内上传")])
                        ]),
                        _c("div", { staticClass: "uploader-tip" }, [
                          _vm._v("建议图片比例16：9，支持jpg、png，不超过5m")
                        ])
                      ])
                    ])
                  ])
                ]
              )
            : _c(
                "div",
                { staticClass: "preview-container" },
                [
                  _c(
                    "div",
                    { staticClass: "preview-wrapper" },
                    [
                      _c("el-image", {
                        style: {
                          width: "168px",
                          height: "100px"
                        },
                        attrs: {
                          src: `${_vm.file}${_vm.path}`,
                          fit: "cover",
                          "preview-src-list": [`${_vm.file}${_vm.path}`]
                        }
                      }),
                      _c("div", { staticClass: "text" }, [_vm._v("封面预览")])
                    ],
                    1
                  ),
                  _c(
                    "sb-cover-upload",
                    {
                      attrs: { "upload-folder": _vm.uploadFolder },
                      model: {
                        value: _vm.path,
                        callback: function($$v) {
                          _vm.path = $$v
                        },
                        expression: "path"
                      }
                    },
                    [
                      _c("div", { staticClass: "image-uploader" }, [
                        _c("div", { staticClass: "uploader-btn" }, [
                          _c("img", {
                            staticClass: "image",
                            attrs: { src: require("./upload_icon.png") }
                          }),
                          _c("div", { staticClass: "uploader-text" }, [
                            _c("a", [_vm._v("重新上传")]),
                            _c("span", [_vm._v("或将文件拖至框内上传")])
                          ]),
                          _c("div", { staticClass: "uploader-tip" }, [
                            _vm._v("建议图片比例16：9，支持jpg、png，不超过5m")
                          ])
                        ])
                      ])
                    ]
                  )
                ],
                1
              )
        ],
        1
      ),
      _c("el-divider", { staticClass: "zwx-divider" }),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("默认封面")]),
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.path,
                callback: function($$v) {
                  _vm.path = $$v
                },
                expression: "path"
              }
            },
            [
              _c(
                "div",
                { staticClass: "default-cover-wrapper" },
                _vm._l(6, function(item) {
                  return _c(
                    "div",
                    { key: item, staticClass: "item" },
                    [
                      _c(
                        "el-radio",
                        {
                          staticClass: "zwx-radio",
                          attrs: {
                            label: `/file/saas/common/base/trainClass/default/${item}.png`
                          }
                        },
                        [_vm._v(_vm._s(""))]
                      ),
                      _c("el-image", {
                        style: {
                          width: "168px",
                          height: "100px"
                        },
                        attrs: {
                          src: `${_vm.file}/file/saas/common/base/trainClass/default/${item}.png`,
                          fit: "cover",
                          "preview-src-list": [
                            `${_vm.file}/file/saas/common/base/trainClass/default/${item}.png`
                          ]
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            ]
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "ms-dialog-footer-specical",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.commit()
                }
              }
            },
            [_vm._v("确认")]
          ),
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [_vm._v("取消")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
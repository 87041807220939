<template>
  <el-dialog class="zwx-dialog" title="封面选择" :visible.sync="visible" @close="close()" :close-on-click-modal="false" :destroy-on-close="true">
    <div class="content">
      <div class="title">自定义封面</div>
      <sb-cover-upload v-if="!path" v-model="path" :upload-folder="uploadFolder">
        <div class="image-uploader large">
          <div class="uploader-btn">
            <img class="image" :src="require('./upload_icon.png')" />
            <div class="right">
              <div class="uploader-text">
                <a>点击此处</a>
                <span>或将文件拖至框内上传</span>
              </div>
              <div class="uploader-tip">建议图片比例16：9，支持jpg、png，不超过5m</div>
            </div>
          </div>
        </div>
      </sb-cover-upload>

      <div v-else class="preview-container">
        <div class="preview-wrapper">
          <el-image
            :src="`${file}${path}`"
            fit="cover"
            :preview-src-list="[`${file}${path}`]"
            :style="{
              width: '168px',
              height: '100px',
            }"
          />
          <div class="text">封面预览</div>
        </div>
        <sb-cover-upload v-model="path" :upload-folder="uploadFolder">
          <div class="image-uploader">
            <div class="uploader-btn">
              <img class="image" :src="require('./upload_icon.png')" />
              <div class="uploader-text">
                <a>重新上传</a>
                <span>或将文件拖至框内上传</span>
              </div>
              <div class="uploader-tip">建议图片比例16：9，支持jpg、png，不超过5m</div>
            </div>
          </div>
        </sb-cover-upload>
      </div>
    </div>
    <el-divider class="zwx-divider" />

    <div class="content">
      <div class="title">默认封面</div>
      <el-radio-group v-model="path">
        <div class="default-cover-wrapper">
          <div class="item" v-for="item in 6" :key="item">
            <el-radio class="zwx-radio" :label="`/file/saas/common/base/trainClass/default/${item}.png`">{{ '' }}</el-radio>
            <el-image
              :src="`${file}/file/saas/common/base/trainClass/default/${item}.png`"
              fit="cover"
              :preview-src-list="[`${file}/file/saas/common/base/trainClass/default/${item}.png`]"
              :style="{
                width: '168px',
                height: '100px',
              }"
            />
          </div>
        </div>
      </el-radio-group>
    </div>
    <span slot="footer" class="ms-dialog-footer-specical">
      <el-button class="zwx-button zwx-button-32" type="primary" @click="commit()">确认</el-button>
      <el-button class="zwx-button zwx-button-32" @click="close()">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import SbCoverUpload from './SbCoverUpload.vue'

export default {
  name: 'CoverUploadDialog',
  components: {
    SbCoverUpload,
  },
  props: {
    uploadFolder: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      api: this.$store.state.api,
      file: this.$store.state.file,
      users: this.$store.state.users,
      visible: false,
      path: '',
    }
  },
  created() {},
  methods: {
    commit() {
      this.$emit('confirm', this.path)
      this.close()

      // let data = {
      //   uid: this.dataInfo.uid || null,
      //   ifEnable: !this.dataInfo.ifEnable,
      // }
      // this.$system.post(this.api + '/saas/modifySaasUserState-1', data, true, true, this.commitSuccess, this.error)
    },
    commitSuccess(data) {
      if (data.type === '00') {
        this.$system.notify('成功', data.mess, 'success')
        this.close()
        this.$emit('confirm')
      } else if (data.type === '99') {
        this.$router.push({ name: 'MsError', params: { mess: data.mess } })
      } else {
        this.$system.notify('错误', data.mess, 'error')
        this.$emit('loading', false)
      }
    },
    // 关闭弹框
    close() {
      this.visible = false
    },
    // 打开弹框
    setShow(path) {
      this.path = path || ''
      this.visible = true
    },
    error() {
      this.$system.notify('错误', '网络连接失败', 'error')
      this.$emit('loading', false)
    },
  },
  mounted() {},
}
</script>

<style lang="less" scoped>
/deep/ .zwx-divider {
  margin: 0;
}
/deep/ .el-dialog {
  width: 600px !important;
  min-width: 600px !important;
  .ms-dialog-footer-specical {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    box-sizing: border-box;
  }
}
/deep/ .ms-form {
  .el-form-item {
    display: block !important;
  }
  .ms-select {
    width: 100%;
    .el-input__inner {
      width: 100% !important;
    }
  }
}
.content {
  padding: 20px 24px 0;
  box-sizing: border-box;
  .title {
    margin-bottom: 16px;
    font-size: 14px;
    color: #3a485e;
    line-height: 14px;
  }
}
/deep/ .file-upload,
/deep/ .el-upload {
  display: block;
  margin-bottom: 24px;
  .el-upload-dragger {
    width: 100%;
    height: 100%;
    border-width: 2px;
  }
}
.image-uploader {
  min-width: 361px;
  height: 144px;
  background: #fcfdff;
  // border: 1px dashed #c5cef0;
  border-radius: 2px;
  &.large {
    .uploader-btn {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      .right {
        text-align: left;
      }
    }
  }
  .uploader-btn {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 16px 0;
    height: 100%;
  }
  .uploader-text {
    margin-bottom: 12px;
    color: #777f89;
    a {
      margin-right: 6px;
      font-size: 14px;
      color: #4a75f5;
      line-height: 14px;
    }
  }
  .uploader-tip {
    font-size: 14px;
    color: #a6a6a6;
  }
}
.preview-container {
  display: flex;
  justify-content: space-between;
  .preview-wrapper {
    padding-top: 12px;
    text-align: center;
    .text {
      margin-top: 10px;
      font-size: 14px;
      color: #777f89;
      line-height: 14px;
    }
  }
}
.default-cover-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .item {
    position: relative;
    flex-basis: 168px;
    margin-bottom: 24px;
    height: 100px;
    .zwx-radio {
      position: absolute;
      // top: 0;
      right: 0;
      // bottom: 0;
      // left: 0;
      z-index: 1;
      margin: 0 !important;
      height: auto !important;
    }
  }
}
</style>

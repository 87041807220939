<template>
  <div class="ms-shelve-goods-edit">
    <el-form class="zwx-form edit-form" key="trainingActivityListForm" ref="trainingActivityListFormRef" :model="trainingActivityListForm" :rules="trainingActivityListFormRule" label-position="right" @submit.native.prevent>
      <!-- 基础信息部分 -->
      <base-headline title="基本信息" class="headline" />
      <div class="edit-row" style="margin-top: 16px">
        <el-form-item label-width="125px" label="培训班名称：" prop="trainingActivityName">
          <el-input style="width: 400px !important" class="zwx-input" v-model.trim="trainingActivityListForm.trainingActivityName" placeholder="请输入培训班名称" clearable maxlength="50"></el-input>
        </el-form-item>
      </div>
      <div class="edit-row">
        <el-form-item label-width="125px" label="培训班封面：" prop="activityCoverPath">
          <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-upload2" @click="onCoverDialogOpen">{{ trainingActivityListForm.activityCoverPath ? '重新选择' : '选择' }}</el-button>
          <br />
          <el-image
            v-if="trainingActivityListForm.activityCoverPath"
            :src="`${file}${trainingActivityListForm.activityCoverPath}`"
            fit="contain"
            :preview-src-list="[`${file}${trainingActivityListForm.activityCoverPath}`]"
            :style="{
              margin: '10px 0 0',
              maxWidth: '140px',
            }"
          />
        </el-form-item>
      </div>
      <div class="edit-row" style="display:flex;align-items:center;font-size: 14px;color: #3A485E;line-height:14px;margin-bottom:16px;margin-left:55px">
        <!-- <el-form-item label-width="125px" label="主办单位：">
          {{ userInfo.fkByUnitId.unitName }}
        </el-form-item> -->
        <div>主办单位：</div>
        <div>{{ userInfo.fkByUnitId.unitName }}</div>
      </div>
      <div class="edit-row">
        <el-form-item label-width="125px" label="班主任：" prop="linkName">
          <el-input class="zwx-input" v-model="trainingActivityListForm.linkName" placeholder="请输入班主任" :maxlength="10" style="width: 200px !important" />
        </el-form-item>
      </div>
      <div class="edit-row">
        <el-form-item label-width="125px" label="联系方式：" prop="linkTel">
          <el-input class="zwx-input" v-model="trainingActivityListForm.linkTel" placeholder="请输入联系方式" style="width: 200px !important" />
        </el-form-item>
      </div>
      <div class="edit-row">
        <el-form-item label-width="125px" label="开班最少人数：">
          <el-input style="width: 200px !important" class="zwx-input" v-model.trim="trainingActivityListForm.leastNum" placeholder="" @input="$validate.nonnegativeDouble($data['trainingActivityListForm'], 'leastNum', 5, 0)" maxlength="5" clearable>
            <template slot="append">人</template>
          </el-input>
        </el-form-item>
      </div>
      <div class="edit-row">
        <el-form-item label-width="125px" label="最大报名人数：">
          <el-input style="width: 200px !important" class="zwx-input" v-model.trim="trainingActivityListForm.mostNum" placeholder="" @input="$validate.nonnegativeDouble($data['trainingActivityListForm'], 'mostNum', 5, 0)" clearable>
            <template slot="append">人</template>
          </el-input>
        </el-form-item>
      </div>
      <div class="edit-row">
        <el-form-item label="报名时间：" label-width="125px" prop="registStartTime">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width: 198px !important" v-model="trainingActivityListForm.registStartTime" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="$validate.noBigDate($data['trainingActivityListForm'], 'registEndTime', true)" placeholder="开始日期" />
        </el-form-item>
        <span style="margin-right: 20px">-</span>
        <el-form-item prop="registEndTime">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width: 198px !important" v-model="trainingActivityListForm.registEndTime" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="$validate.noSmallDate($data['trainingActivityListForm'], 'registStartTime', true)" placeholder="结束日期" />
        </el-form-item>
      </div>

      <!-- <div class="edit-row">
        <el-form-item label="培训班内容：" label-width="125px" prop="trainingCourseContent">
          <el-checkbox-group v-model="trainingActivityListForm.trainingCourseContent">
            <el-checkbox :label="1">线上课程</el-checkbox>
            <el-checkbox :label="2">线下培训</el-checkbox>
            <el-checkbox :label="3">培训考试</el-checkbox>
            <el-checkbox :label="4">培训发证</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </div> -->
      <!-- <div class="edit-row">
        <el-form-item label-width="125px" label="是否线上课程：" prop="ifOnLineCourse">
          <el-radio-group v-model="trainingActivityListForm.ifOnLineCourse" disabled>
            <el-radio :label="false">否</el-radio>
            <el-radio :label="true">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </div> -->
      <div class="edit-row">
        <el-form-item label="线上课程有效期：" label-width="125px" prop="courseStartTime">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width: 198px !important" v-model="trainingActivityListForm.courseStartTime" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="$validate.noBigDate($data['trainingActivityListForm'], 'courseEndTime', true)" placeholder="开始日期" />
        </el-form-item>
        <span style="margin-right: 20px">-</span>
        <el-form-item prop="courseEndTime">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width: 198px !important" v-model="trainingActivityListForm.courseEndTime" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="$validate.noSmallDate($data['trainingActivityListForm'], 'courseStartTime', true)" placeholder="结束日期" />
        </el-form-item>
      </div>
      <!-- <div class="edit-row">
        <el-form-item label-width="125px" label="是否线下培训：" prop="ifOffLineTraining">
          <el-radio-group v-model="trainingActivityListForm.ifOffLineTraining">
            <el-radio :label="false">否</el-radio>
            <el-radio :label="true">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </div> -->
      <div v-if="ifShowOff" class="edit-row">
        <el-form-item label="线下培训时间：" label-width="125px" prop="offLineStartTime">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width: 198px !important" v-model="trainingActivityListForm.offLineStartTime" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="$validate.noBigDate($data['trainingActivityListForm'], 'offLineEndTime', true)" placeholder="开始日期" />
        </el-form-item>
        <span style="margin-right: 20px">-</span>
        <el-form-item prop="offLineEndTime">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width: 198px !important" v-model="trainingActivityListForm.offLineEndTime" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="$validate.noSmallDate($data['trainingActivityListForm'], 'offLineStartTime', true)" placeholder="结束日期" />
        </el-form-item>
      </div>
      <div v-if="ifShowOff" class="edit-row">
        <el-form-item label-width="125px" label="线下培训地点：" prop="offLineTrainingAddr">
          <el-input style="width: 400px !important" class="zwx-input" v-model.trim="trainingActivityListForm.offLineTrainingAddr" placeholder="请输入线下培训地点" clearable maxlength="50" @input="offLineTrainingAddrInput"></el-input>
        </el-form-item>
      </div>
      <div class="edit-row">
        <el-form-item label-width="125px" label="是否推荐展示：" prop="recommendDisplay">
          <el-radio-group v-model="trainingActivityListForm.recommendDisplay">
            <el-radio class="zwx-radio" :label="true">是</el-radio>
            <el-radio class="zwx-radio" :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div v-if="unitNature == 10180003" class="edit-row">
        <el-form-item label-width="125px" label="注意事项：" prop="mattersAttention">
          <el-input class="zwx-textarea" type="textarea" v-model.trim="trainingActivityListForm.mattersAttention" style="width: 640px !important;" :autosize="{ minRows: 5, maxRows: 8 }" maxlength="1000" placeholder="请输入" clearable></el-input>
        </el-form-item>
      </div>
      <div v-if="unitNature == 10180003" class="edit-row">
        <el-form-item label-width="125px" label="备注内容：" prop="remark">
          <el-input class="zwx-textarea" type="textarea" v-model.trim="trainingActivityListForm.remark" style="width: 640px !important;" :autosize="{ minRows: 5, maxRows: 8 }" maxlength="1000" placeholder="请输入" clearable></el-input>
        </el-form-item>
      </div>
      <div class="edit-row" v-if="unitNature == 10180003">
        <el-form-item label-width="125px" label="附件：" prop="fileName">
          <file-upload ref="coverPictureRef" accept=".png,.jpeg,.jpg,.JPG,.JPEG,.PNG,.pdf" @showFileList="picFileShowFileList" @success="picFileUploadSuccess" :size="50 * 1024 * 1024" sizeInfo="50M">
            <el-button class="zwx-button zwx-button-text-26" icon="el-icon-upload2" v-if="$zwxBase.verifyIsBlank(trainingActivityListForm.annexList)">上传</el-button>
          </file-upload>
          <div class="show-file" v-if="isFileShow">
            <el-button class="zwx-button zwx-button-text-26" @click="openFilePreview(trainingActivityListForm.annexList, 0)">查看</el-button>
            <el-button class="zwx-button zwx-button-text-26" type="danger" @click="deletionPicFile(0)">删除</el-button>
          </div>
        </el-form-item>
      </div>

      <!-- <div class="edit-row">
        <el-form-item label-width="125px" label="是否培训考试：" prop="ifExam">
          <el-radio-group v-model="trainingActivityListForm.ifExam">
            <el-radio :label="false">否</el-radio>
            <el-radio :label="true">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </div> -->
      <!-- <div class="edit-row" v-if="trainingActivityListForm.ifExam">
        <el-form-item label-width="125px" label="考试类型：" prop="examType">
          <el-radio-group v-model="trainingActivityListForm.examType">
            <el-radio :label="1">线上考试</el-radio>
            <el-radio :label="2">线下考试</el-radio>
          </el-radio-group>
        </el-form-item>
      </div> -->
      <!-- <div class="edit-row">
        <el-form-item label-width="125px" label="签到二维码路径：" prop="signInQrCode">
          <el-input style="width: 400px !important" class="zwx-input" v-model.trim="trainingActivityListForm.signInQrCode" placeholder="请输入" clearable maxlength="200" />
        </el-form-item>
      </div>
      <div class="edit-row">
        <el-form-item label-width="125px" label="报名二维码路径：" prop="registrationQrCode">
          <el-input style="width: 400px !important" class="zwx-input" v-model.trim="trainingActivityListForm.registrationQrCode" placeholder="请输入" clearable maxlength="200" />
        </el-form-item>
      </div> -->
      <!-- <div class="edit-row">
        <el-form-item label-width="125px" label="是否培训发证：" prop="ifCertificate">
          <el-radio-group v-model="trainingActivityListForm.ifCertificate">
            <el-radio :label="false">否</el-radio>
            <el-radio :label="true">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="edit-row" v-if="trainingActivityListForm.ifCertificate">
        <el-form-item label-width="125px" label="培训发证：" prop="certificateType">
          <el-radio-group v-model="trainingActivityListForm.certificateType">
            <el-radio :label="1">线上发证</el-radio>
            <el-radio :label="2">线下发证</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="edit-row" v-if="trainingActivityListForm.certificateType == 1 && trainingActivityListForm.ifCertificate">
        <el-form-item label-width="125px" label="发证单位：" prop="certificateUnit">
          {{ trainingActivityListForm.certificateUnit }}
        </el-form-item>
      </div>
      <div class="edit-row" v-if="trainingActivityListForm.certificateType == 1 && trainingActivityListForm.ifCertificate">
        <el-form-item label-width="125px" label="发证签名：" prop="certificateSeal">
          <el-button class="zwx-button zwx-button-text-26" @click="openFilePreview1(trainingActivityListForm.certificateSeal)">查看</el-button>
        </el-form-item>
      </div> -->

      <!-- 课程配置 -->
      <base-headline title="课程配置" class="courseExpline">
        <el-button class="zwx-button zwx-button-24" icon="el-icon-plus" @click="addCourseExpList" v-if="trainingActivityListForm.enrollSum == 0">添加</el-button>
      </base-headline>
      <!-- 列表展示部分 -->
      <el-table class="zwx-table" row-key="rid" :data="trainingActivityListForm.activityCourseExpList" border stripe tooltip-effect="light">
        <el-table-column prop="traineeType" label="学员类型" width="220" header-align="center" align="left">
          <template slot-scope="scope">
            <el-form-item :prop="'activityCourseExpList.' + scope.$index + '.traineeType'" :rules="trainingActivityListFormRule.traineeType" readonly>
              <el-select v-model="scope.row.traineeType" placeholder="请选择" class="zwx-select" popper-class="zwx-select-popper" @change="val => traineeTypeChange(val, scope.row)">
                <el-option v-for="item in trainingActivityListFormExp.traineeTypeOptions" :key="item.codeNo" :label="item.codeName" :value="item.codeNo"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="trainingType" label="培训类型" width="190" header-align="center" align="center">
          <template slot-scope="scope">
            <el-form-item :prop="'activityCourseExpList.' + scope.$index + '.trainingType'" :rules="trainingActivityListFormRule.trainingType">
              <el-select v-model="scope.row.trainingType" placeholder="请选择" class="zwx-select" popper-class="zwx-select-popper" @change="trainingTypeChange($event, scope.row, scope.$index)">
                <el-option v-for="item in scope.row.trainingTypeOptions" :key="item.codeNo" :label="item.codeName" :value="item.codeNo"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="courseUid" label="课程选择" min-width="150" header-align="center" align="center">
          <template slot-scope="scope">
            <el-form-item :prop="'activityCourseExpList.' + scope.$index + '.courseUid'" :rules="trainingActivityListFormRule.courseUid">
              <el-select v-model="scope.row.courseName" placeholder="请选择" class="zwx-select" popper-class="zwx-select-popper" @change="courseUidChange($event, scope)">
                <el-option v-for="item in scope.row.trainingCourseExpList" :key="item.uuid" :label="item.courseName" :value="{ value: item.uuid, label: item.courseName, onlineTotalClassHour: item.onlineTotalClassHour, offLineTotalClassHour: item.offLineTotalClassHour }"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="onlineTotalClassHour" label="线上课程学时" width="120" header-align="center" align="center"></el-table-column>
        <!-- v-if="trainingActivityListForm.ifOffLineTraining" -->
        <el-table-column prop="offLineTotalClassHour" v-if="ifShowOff" label="线下培训学时" width="120" header-align="center" align="center"></el-table-column>
        <el-table-column v-if="!config.ifOnlineCourses && unitNature != '10180003'" prop="expiryDate" label="报名价格（元）" width="150" header-align="center" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.registType == 1">-</span>
            <el-form-item v-else :prop="'activityCourseExpList.' + scope.$index + '.registPrice'" :rules="trainingActivityListFormRule.registPrice">
              <el-input style="width: 130 !important" class="zwx-input" @input="$validate.number($data.trainingActivityListForm.activityCourseExpList[scope.$index], 'registPrice', 8, 2, 0)" v-model.trim="scope.row.registPrice" placeholder="请输入" clearable />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="registType" label="报名类型" width="180" header-align="center" align="center">
          <template slot-scope="scope">
            <el-form-item :prop="'activityCourseExpList.' + scope.$index + '.registType'" :rules="trainingActivityListFormRule.registType">
              <el-radio-group class="zwx-radio-group" v-model="scope.row.registType">
                <el-radio class="zwx-radio" :label="1">非公开</el-radio>
                <el-radio class="zwx-radio" :label="2">公开</el-radio>
              </el-radio-group>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" min-width="100" header-align="left" align="left">
          <template slot-scope="scope">
            <el-button class="zwx-button zwx-button-text-26" type="danger" @click="deleteList(scope.$index)" v-if="trainingActivityListForm.enrollSum == 0">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <fixed-footer>
      <el-button class="zwx-button zwx-button-32" type="primary" @click="submit()">保存</el-button>
      <!-- <el-button class="zwx-button zwx-button-32" @click="back()">返回</el-button> -->
    </fixed-footer>

    <!-- 教材/考试 弹框 -->
    <base-dialog ref="coursewareDialogRef" class="courseware-dialog" @determine="coursewareDialogSubmit" title="选择课程" @cancel="coursewareDialogCancel" @close="coursewareDialogClose" :pagination="true" @currentPage="coursewareDialogCurrent" :total="trainingActivityListFormExp.materialTotal">
      <el-form ref="trainingActivityListFormExp" class="zwx-form" :model="trainingActivityListFormExp" style="height: 50px !important">
        <div class="edit-form">
          <el-form-item label="课程名称：" label-width="70px">
            <el-input class="zwx-input cc-input" style="cursor: pointer; min-width: 30px !important; width: 295px !important" placeholder="输入查询" v-model.trim="trainingActivityListFormExp.materialNameLike" maxlength="200" clearable></el-input>
          </el-form-item>
          <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="getTeachingMaterialList(1, trainingActivityListFormExp.coursewareIndex)">查询</el-button>
        </div>
      </el-form>
      <!-- 教材列表展示部分 -->
      <el-table ref="teachingMaterialTable" class="zwx-table" row-key="uuid" :data="trainingActivityListFormExp.teachingMaterialList" stripe tooltip-effect="light" max-height="310px">
        <el-table-column label="选择" width="80" header-align="center" align="center">
          <template slot-scope="scope">
            <el-radio class="zwx-radio-course" v-model="trainingActivityListFormExp.courseUid" :label="scope.row.uuid" style="margin-right: unset !important; width: 15px" @change="handleCoursewareSelectionChange(scope.row)"></el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="courseName" label="课程名称" min-width="550" header-align="center" align="left" show-overflow-tooltip></el-table-column>
        <el-table-column prop="totalClassHour" label="学时要求" header-align="center" align="center" fixed="right"></el-table-column>
      </el-table>
    </base-dialog>
    <cover-upload-dialog ref="coverUploadDialog" :upload-folder="'saas/' + config.domainPrefix + '/activity'" @confirm="onCoverUpload" />
  </div>
</template>
<script>
import SbCoverUpload from './SbCoverUpload.vue'
import CoverUploadDialog from './CoverUploadDialog/index.vue'
export default {
  name: 'TrainingManagementEdit',
  components: {
    SbCoverUpload,
    CoverUploadDialog,
  },
  data() {
    return {
      opType: this.$route.params.type,
      api: this.$store.state.api,
      file: this.$store.state.file,
      userInfo: this.$store.state.users,
      config: {},
      trainingActivityListForm: {
        annexList: [],
        activityCoverPath: '',
        linkName: '',
        linkTel: '',
        recommendDisplay: true,
        trainingActivityName: '',
        agencyUnitname: '',
        ifOnLineCourse: true,
        ifOffLineTraining: true,
        ifExam: true,
        ifCertificate: true,
        registStartTime: '',
        registEndTime: '',
        offLineStartTime: '',
        offLineTrainingAddr: '',
        courseStartTime: '',
        courseEndTime: '',
        leastNum: '',
        mostNum: '',
        examType: 2,
        // signInQrCode: '',
        // registrationQrCode: '',
        certificateType: 1,
        certificateUnit: '',
        certificateSeal: '',
        activityCourseExpList: [],
        enrollSum: 0,
      },
      trainingActivityListFormRule: {
        activityCoverPath: [{ required: true, message: '请上传培训班封面', trigger: ['change', 'blur'] }],
        linkName: [{ required: true, message: '请输入班主任', trigger: ['change', 'blur'] }],
        linkTel: [
          { required: true, message: '请输入联系方式', trigger: ['change', 'blur'] },
          { required: true, validator: this.$validate.mbPeLdline, trigger: ['change', 'blur'] },
        ],
        trainingActivityName: [{ required: true, message: '请输入培训班名称', trigger: ['change', 'blur'] }],
        registStartTime: [{ required: true, message: '请选择报名开始时间', trigger: ['change', 'blur'] }],
        registEndTime: [{ required: true, message: '请选择报名结束时间', trigger: ['change', 'blur'] }],
        offLineStartTime: [{ required: true, message: '请选择线下培训开始时间', trigger: ['change', 'blur'] }],
        offLineEndTime: [{ required: true, message: '请选择线下培训结束时间', trigger: ['change', 'blur'] }],
        offLineTrainingAddr: [{ required: true, message: '请输入线下培训地点', trigger: ['change', 'blur'] }],
        courseStartTime: [{ required: true, message: '请选择线上课程有效开始时间', trigger: ['change', 'blur'] }],
        courseEndTime: [{ required: true, message: '请选择线上课程有效结束时间', trigger: ['change', 'blur'] }],
        examType: [{ required: true, message: '请选择考试类型', trigger: ['change', 'blur'] }],
        // signInQrCode: [{ required: true, message: '请输入签到二维码路径', trigger: ['change', 'blur'] }],
        // registrationQrCode: [{ required: true, message: '请输入报名二维码路径', trigger: ['change', 'blur'] }],
        certificateType: [{ required: true, message: '请选择培训证书', trigger: ['change', 'blur'] }],
        traineeType: [{ required: true, message: '请选择学员类型', trigger: ['change', 'blur'] }],
        trainingType: [{ required: true, message: '请选择培训类型', trigger: ['change', 'blur'] }],
        courseUid: [{ required: true, message: '请选择线上课程', trigger: ['change', 'blur'] }],
        registPrice: [{ required: true, message: '请输入报名价格', trigger: ['change', 'blur'] }],
        registType: [{ required: true, message: '请选择报名类型', trigger: ['change', 'blur'] }],
      },
      trainingActivityListFormExp: {
        coursewareIndex: '',
        coursewareCurrentPage: '', // 弹框页码
        materialTotal: 0, // 教材分页总数
        pageSize: '',
        materialNameLike: '', //教材名称查询
        courseUid: '',
        courseName: '',
        onlineTotalClassHour: '', //课时
        traineeTypeOptions: [],
        trainingTypeOptions: [],
        teachingMaterialList: [], //教材集合
      },
      //线上课程数组
      trainingCourseExpList: [],
      ifShowOff: true,
      unitNature: null,
      isFileShow: false,
    }
  },
  computed: {
    courseExpShowList() {
      const data = this.trainingActivityListForm.activityCourseExpList.filter(v => {
        v.ifDelete !== true
      })
      return data
    },
  },
  created() {
    this.$SimpleCodeTools.getConfig(data => {
      this.config = data
    })
    let config = window.top.vm.$store.state.config
    this.ifShowOff = config.ifTrainingClassOffline
    // this.ifShowExamd = !!config.ifOnlineExam && !!config.ifTrainingExamSplit
  },
  mounted() {
    this.unitNature = window.top.vm.$store.state.config.unitNature
    this.$simpleCodeTools.getSimpleCodeListByCodeTypeCodes('5002', data => {
      this.trainingActivityListFormExp.trainingTypeOptions = data['5002']
      this.trainingActivityListFormExp.traineeTypeOptions = this.manyListFul(data['5002'], '')
      this.init()
    })
  },
  methods: {
    picFileShowFileList(showList) {
      // showList.forEach(item => {
      //   // let flag = this.trainingActivityListForm.annexList.some(e => e.fileName == item)
      //   // if (flag) {
      //   //   return
      //   // }

      // })
      this.trainingActivityListForm.annexList = [
        {
          fileName: showList[0],
          fileIconShow: this.$zwxBase.dealfilePrefix(showList[0]),
          check: false,
        },
      ]
      if (showList && showList.length > 0) {
        this.generateFilesUid()
      }
    },
    generateFilesUid() {
      this.$emit('loading', true)
      let data = {
        folder: 'saas/' + this.config.domainPrefix + '/course',
      }
      this.$system.get(this.api + '/systematic/tools/getAnnexUploadUid-1', data, true, true, data => {
        if (data.type === '00') {
          this.$refs.coverPictureRef.submit(data.annexUploadUid)
          this.$emit('loading', false)
        } else {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      })
    },
    /**
     * 课程图片文件上传成功回调
     */
    picFileUploadSuccess(fileList) {
      // 文件集合

      this.isFileShow = this.$zwxBase.verifyIsNotBlank(this.trainingActivityListForm.annexList)

      if (this.$zwxBase.verifyIsNotBlank(this.trainingActivityListForm.annexList) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in this.trainingActivityListForm.annexList) {
          for (let j in fileList) {
            if (this.trainingActivityListForm.annexList[i].fileName === fileList[j].fileName) {
              this.trainingActivityListForm.annexList[i].filePath = fileList[j].filePath
            }
          }
        }
      }
      // 保存
      //this.saveData()
    },
    /**
     * 删除课程图片文件
     */
    deletionPicFile(index) {
      let fileName = this.trainingActivityListForm.annexList[index].fileName
      let filePath = this.trainingActivityListForm.annexList[index].filePath
      this.trainingActivityListForm.annexList.splice(index, 1)
      if (this.$zwxBase.verifyIsBlank(filePath)) {
        this.$refs.coverPictureRef.deleteIndex(index)
      }
      let fileList = this.$refs.coverPictureRef.showFileList || []
      this.isFileShow = false
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.coverPictureRef.deleteIndex(j)
            fileList = this.$refs.coverPictureRef.showFileList || []
            continue
          }
        }
      }
    },

    onCoverDialogOpen() {
      this.$refs.coverUploadDialog.setShow(this.trainingActivityListForm.activityCoverPath)
    },
    onCoverUpload(path) {
      this.trainingActivityListForm.activityCoverPath = path
      this.$refs.trainingActivityListFormRef.validateField('activityCoverPath')
    },
    //线上课程change
    courseUidChange(val, scope) {
      // 将val对象解构
      let courseUid = val.value
      let courseName = val.label
      let onlineTotalClassHour = val.onlineTotalClassHour
      let offLineTotalClassHour = val.offLineTotalClassHour
      this.trainingActivityListForm.activityCourseExpList[scope.$index].courseName = courseName
      this.trainingActivityListForm.activityCourseExpList[scope.$index].courseUid = courseUid
      this.trainingActivityListForm.activityCourseExpList[scope.$index].onlineTotalClassHour = onlineTotalClassHour
      this.trainingActivityListForm.activityCourseExpList[scope.$index].offLineTotalClassHour = offLineTotalClassHour
      this.$forceUpdate()
    },
    //获取线上课程数据
    getOnlineCourse(courseTraineeType, courseTrainingType, index) {
      let data = {
        courseTraineeType,
        courseTrainingType,
      }
      this.$system.postJson(
        this.api + '/training/platform/getCourseChooseList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.$set(this.trainingActivityListForm.activityCourseExpList[index], 'trainingCourseExpList', data.trainingCourseExpList)
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    //课程配置培训类型change
    trainingTypeChange(val, row, index) {
      //调用根据分类、类型获取可选择的课程
      if (val) {
        row.courseName = ''
        row.courseUid = ''
        row.onlineTotalClassHour = ''
        row.offLineTotalClassHour = ''
        this.trainingCourseExpList = []
        this.getOnlineCourse(row.traineeType, row.trainingType, index)
      }
    },
    offLineTrainingAddrInput(val) {
      this.trainingActivityListForm.offLineTrainingAddr = val.replace(/[^\u4E00-\u9FA5|\d|\a-zA-Z|\r\n\s,.?!，。？！…—&$=()-+/*{}[\]]/g, '')
    },
    init() {
      if (this.opType === 'add') {
        this.$emit('breadcrumb', '培训班创建', true)
        this.trainingActivityListForm = {
          activityCoverPath: '',
          linkName: '',
          linkTel: '',
          recommendDisplay: true,
          trainingActivityName: '',
          agencyUnitname: '',
          ifOnLineCourse: true,
          ifOffLineTraining: true,
          ifExam: true,
          ifCertificate: true,
          registStartTime: '',
          registEndTime: '',
          offLineStartTime: '',
          offLineTrainingAddr: '',
          courseStartTime: '',
          courseEndTime: '',
          leastNum: '',
          mostNum: '',
          examType: 2,
          certificateType: 1,
          annexList: [],
          // certificateUnit: '',
          // certificateSeal: '',
          // signInQrCode: '',
          registrationQrCode: '',
          activityCourseExpList: [],
          enrollSum: 0,
        }
        // this.getCertificateConfig()
      } else {
        this.$emit('breadcrumb', '培训班编辑', true)
        this.$system.get(
          this.api + '/training/platform/getTrainingActivityMainInfo-1',
          { uuid: this.$route.params.row.uuid, editType: 1 },
          true,
          true,
          data => {
            this.$emit('loading', false)

            if (data.type == '00') {
              try {
                const trainingActivityListForm = {
                  linkName: '',
                  linkTel: '',
                  ...data.activityMainExp,
                }
                if (trainingActivityListForm.linkTel) {
                  trainingActivityListForm.linkTel = this.$zwxSm.sm4JsDecrypt(trainingActivityListForm.linkTel)
                }

                this.trainingActivityListForm = JSON.parse(JSON.stringify(trainingActivityListForm))
                this.trainingActivityListForm.activityCourseExpList = this.trainingActivityListForm.activityCourseExpList || []
                if (this.trainingActivityListForm.filePath && this.trainingActivityListForm.filePath.length > 0) {
                  this.trainingActivityListForm.annexList = [
                    {
                      fileName: this.trainingActivityListForm.fileName,
                      filePath: this.trainingActivityListForm.filePath,
                    },
                  ]
                  this.isFileShow = true
                }
                if (this.trainingActivityListForm.activityCourseExpList.length > 0) {
                  this.trainingActivityListForm.activityCourseExpList.forEach((val, idx) => {
                    if (val.traineeType) {
                      this.trainingActivityListForm.activityCourseExpList[idx].trainingTypeOptions = this.manyListFul(this.trainingActivityListFormExp.trainingTypeOptions, val.traineeType)
                    }
                  })
                }
              } catch (error) {}
              // this.getCertificateConfig()
            } else {
              this.$system.notify('错误', data.mess, 'error')
            }
          },
          () => {
            this.$emit('loading', false)
            this.$system.notify('错误', '网络连接失败', 'error')
          }
        )
      }
    },
    traineeTypeChange(data, arr) {
      arr.trainingType = ''
      arr.trainingTypeOptions = this.manyListFul(this.trainingActivityListFormExp.trainingTypeOptions, data)
      arr.courseName = ''
      arr.courseUid = ''
      arr.onlineTotalClassHour = ''
      arr.offLineTotalClassHour = ''
    },
    manyListFul(data, pid) {
      //递归方法 生成 json tree 数据
      var result = [],
        temp,
        obj
      data.some(e => {
        let LevelNoArr = e.codeLevelNo.split('.')
        if ((e.codeLevelNo == e.codeNo && pid == '') || LevelNoArr[LevelNoArr.length - 2] == pid) {
          obj = { ...e }
          temp = this.manyListFul(data, e.codeNo)
          if (temp.length > 0) {
            obj.children = temp
          }
          result.push(obj)
        }
      })
      return result
    },
    addCourseExpList() {
      this.trainingActivityListForm.activityCourseExpList.push({
        traineeType: '',
        trainingType: '',
        courseUid: '',
        courseName: '',
        onlineTotalClassHour: '',
        offLineTotalClassHour: '',
        expiryDate: '',
      })
    },
    deleteList(index) {
      this.$system.msgbox('', '提示', '是否确认删除？', '确定', '取消', () => {
        this.trainingActivityListForm.activityCourseExpList.splice(index, 1)
        this.$forceUpdate()
      })
    },
    submit() {
      if (this.trainingActivityListForm.activityCourseExpList.length == 0) {
        this.$system.notify('提示', '至少添加一个线上课程', 'info')
        return
      }
      this.$refs.trainingActivityListFormRef.validate(valid => {
        if (valid) {
          this.$emit('loading', true)
          let data = { ...this.trainingActivityListForm }
          if (this.trainingActivityListForm.annexList.length > 0) {
            data.fileName = this.trainingActivityListForm.annexList[0].fileName
            data.filePath = this.trainingActivityListForm.annexList[0].filePath
          } else {
            data.fileName = ''
            data.filePath = ''
          }

          data.linkTel = this.$zwxSm.sm4JsEncrypt(data.linkTel)
          data.leastNum = data.leastNum || 0
          data.mostNum = data.mostNum || 9999
          this.$system.postJson(
            this.api + '/training/platform/addOrModifyTrainingActivityMain-1',
            data,
            true,
            true,
            data => {
              this.$emit('loading', false)
              if (data.type === '00') {
                this.$notify.success({ title: '成功', message: data.mess })
                this.$router.replace({ name: 'TrainingManagement', params: { fresh: true } })
              } else if (data.type === '99') {
                this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
              } else {
                this.$notify.error({ title: '错误', message: data.mess })
              }
            },
            () => {
              this.$emit('loading', false)
              this.$notify.error({ title: '错误', message: '网络连接失败' })
            }
          )
        }
      })
    },
    back() {
      this.$router.replace({ name: 'TrainingManagement' })
    },
    openFilePreview(files) {
      var file = files[0]
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: [{
              fileName:file.fileName,
              filePath:file.filePath
            }],
            index: 0,
          },
        },
        '*'
      )
      // this.$system.downloadUrlFile(file.fileName, process.env.VUE_APP_FILE + file.filePath)
    },
    /**
     * 多个文件预览
     */
    openFilePreview1(url) {
      this.$system.downloadUrlFile(this.trainingActivityListForm.certificateUnit + '签名', url)
    },
    /**
     * 打开教材弹框
     */
    getMsDialog(row, index) {
      this.trainingActivityListFormExp.coursewareIndex = index
      this.trainingActivityListFormExp.courseUid = row.courseUid + ''
      this.trainingActivityListFormExp.courseName = row.courseName
      this.getTeachingMaterialList(1, this.trainingActivityListFormExp.coursewareIndex)
      this.$refs.coursewareDialogRef.show(true)
    },
    /**
     * 教材列表查询
     */
    getTeachingMaterialList(currentPage, coursewareIndex) {
      this.trainingActivityListFormExp.coursewareCurrentPage = currentPage
      var filterRidList = []
      if (this.$zwxBase.verifyIsNotBlank(this.trainingActivityListForm.activityCourseExpList)) {
        for (let index = 0; index < this.trainingActivityListForm.activityCourseExpList.length; index++) {
          const element = this.trainingActivityListForm.activityCourseExpList[index]
          if (this.$zwxBase.verifyIsNotBlank(element.courseUid) && this.$zwxBase.verifyIsNotBlank(coursewareIndex) && coursewareIndex !== index) {
            filterRidList.push(element.courseUid)
          }
        }
      }
      let data = {
        currentPage: this.trainingActivityListFormExp.coursewareCurrentPage,
        courseNameLike: this.trainingActivityListFormExp.materialNameLike,
        pageSize: 7,
        ifEnables: 1,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/online/training/getTrainingCourseList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.trainingActivityListFormExp.teachingMaterialList = data.trainingCourseList
            this.trainingActivityListFormExp.materialTotal = data.trainingCourseCount
            this.trainingActivityListFormExp.pageSize = data.pageSize
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 教材/考试 弹框确定
     */
    coursewareDialogSubmit() {
      this.$set(this.trainingActivityListForm.activityCourseExpList[this.trainingActivityListFormExp.coursewareIndex], 'courseName', this.trainingActivityListFormExp.courseName)
      if (this.$zwxBase.verifyIsNotBlank(this.trainingActivityListFormExp.courseUid)) {
        this.$set(this.trainingActivityListForm.activityCourseExpList[this.trainingActivityListFormExp.coursewareIndex], 'courseUid', this.trainingActivityListFormExp.courseUid)
      }
      if (this.$zwxBase.verifyIsNotBlank(this.trainingActivityListFormExp.onlineTotalClassHour)) {
        this.$set(this.trainingActivityListForm.activityCourseExpList[this.trainingActivityListFormExp.coursewareIndex], 'onlineTotalClassHour', this.trainingActivityListFormExp.onlineTotalClassHour)
      } else {
        this.$set(this.trainingActivityListForm.activityCourseExpList[this.trainingActivityListFormExp.coursewareIndex], 'onlineTotalClassHour', null)
      }
      this.trainingActivityListFormExp.courseUid = ''
      this.$refs.coursewareDialogRef.show(false)
    },
    /**
     * 教材弹框取消
     */
    coursewareDialogCancel() {
      this.$refs.coursewareDialogRef.show(false)
    },
    /**
     * 教材弹框关闭
     */
    coursewareDialogClose() {
      this.trainingActivityListFormExp.coursewareIndex = ''
      this.trainingActivityListFormExp.courseName = ''
      this.trainingActivityListFormExp.courseUid = ''
      this.trainingActivityListFormExp.materialNameLike = ''
      this.trainingActivityListFormExp.classHour = ''
      this.trainingActivityListFormExp.teachingMaterialList = []
      this.trainingActivityListFormExp.coursewareCurrentPage = ''
      this.trainingActivityListFormExp.materialTotal = 0
      this.trainingActivityListFormExp.pageSize = 18
    },
    //教材弹框分页
    coursewareDialogCurrent(currentPage) {
      this.getTeachingMaterialList(currentPage, this.trainingActivityListFormExp.coursewareIndex)
    },
    /**
     * 课件类别选择弹框部分
     */
    //表格选择事件
    handleCoursewareSelectionChange(selection) {
      this.trainingActivityListFormExp.courseName = selection.courseName
      this.trainingActivityListFormExp.courseUid = selection.uuid
      this.trainingActivityListFormExp.onlineTotalClassHour = selection.totalClassHour
    },
  },
}
</script>
<style lang="less" scoped>
.headline /deep/.zwx-head-title {
  border-top: none !important;
}

.courseExpline /deep/.zwx-head-title {
  border-bottom: none !important;
}

/deep/.courseware-dialog .el-radio.zwx-radio-course > .el-radio__input + .el-radio__label {
  display: none;
}

/deep/.courseware-dialog .el-dialog__footer {
  border-top: none !important;
}

/deep/.courseware-dialog .el-dialog {
  width: 800px !important;
  min-width: 800px !important;
}
</style>

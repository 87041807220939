var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ms-shelve-goods-edit" },
    [
      _c(
        "el-form",
        {
          key: "trainingActivityListForm",
          ref: "trainingActivityListFormRef",
          staticClass: "zwx-form edit-form",
          attrs: {
            model: _vm.trainingActivityListForm,
            rules: _vm.trainingActivityListFormRule,
            "label-position": "right"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c("base-headline", {
            staticClass: "headline",
            attrs: { title: "基本信息" }
          }),
          _c(
            "div",
            { staticClass: "edit-row", staticStyle: { "margin-top": "16px" } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "125px",
                    label: "培训班名称：",
                    prop: "trainingActivityName"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "400px !important" },
                    attrs: {
                      placeholder: "请输入培训班名称",
                      clearable: "",
                      maxlength: "50"
                    },
                    model: {
                      value: _vm.trainingActivityListForm.trainingActivityName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.trainingActivityListForm,
                          "trainingActivityName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression:
                        "trainingActivityListForm.trainingActivityName"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "125px",
                    label: "培训班封面：",
                    prop: "activityCoverPath"
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "zwx-button zwx-button-icontext-28",
                      attrs: { icon: "el-icon-upload2" },
                      on: { click: _vm.onCoverDialogOpen }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.trainingActivityListForm.activityCoverPath
                            ? "重新选择"
                            : "选择"
                        )
                      )
                    ]
                  ),
                  _c("br"),
                  _vm.trainingActivityListForm.activityCoverPath
                    ? _c("el-image", {
                        style: {
                          margin: "10px 0 0",
                          maxWidth: "140px"
                        },
                        attrs: {
                          src: `${_vm.file}${_vm.trainingActivityListForm.activityCoverPath}`,
                          fit: "contain",
                          "preview-src-list": [
                            `${_vm.file}${_vm.trainingActivityListForm.activityCoverPath}`
                          ]
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "edit-row",
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "font-size": "14px",
                color: "#3A485E",
                "line-height": "14px",
                "margin-bottom": "16px",
                "margin-left": "55px"
              }
            },
            [
              _c("div", [_vm._v("主办单位：")]),
              _c("div", [_vm._v(_vm._s(_vm.userInfo.fkByUnitId.unitName))])
            ]
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "125px",
                    label: "班主任：",
                    prop: "linkName"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "200px !important" },
                    attrs: { placeholder: "请输入班主任", maxlength: 10 },
                    model: {
                      value: _vm.trainingActivityListForm.linkName,
                      callback: function($$v) {
                        _vm.$set(_vm.trainingActivityListForm, "linkName", $$v)
                      },
                      expression: "trainingActivityListForm.linkName"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "125px",
                    label: "联系方式：",
                    prop: "linkTel"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "200px !important" },
                    attrs: { placeholder: "请输入联系方式" },
                    model: {
                      value: _vm.trainingActivityListForm.linkTel,
                      callback: function($$v) {
                        _vm.$set(_vm.trainingActivityListForm, "linkTel", $$v)
                      },
                      expression: "trainingActivityListForm.linkTel"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                { attrs: { "label-width": "125px", label: "开班最少人数：" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "zwx-input",
                      staticStyle: { width: "200px !important" },
                      attrs: { placeholder: "", maxlength: "5", clearable: "" },
                      on: {
                        input: function($event) {
                          return _vm.$validate.nonnegativeDouble(
                            _vm.$data["trainingActivityListForm"],
                            "leastNum",
                            5,
                            0
                          )
                        }
                      },
                      model: {
                        value: _vm.trainingActivityListForm.leastNum,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.trainingActivityListForm,
                            "leastNum",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "trainingActivityListForm.leastNum"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("人")])],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                { attrs: { "label-width": "125px", label: "最大报名人数：" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "zwx-input",
                      staticStyle: { width: "200px !important" },
                      attrs: { placeholder: "", clearable: "" },
                      on: {
                        input: function($event) {
                          return _vm.$validate.nonnegativeDouble(
                            _vm.$data["trainingActivityListForm"],
                            "mostNum",
                            5,
                            0
                          )
                        }
                      },
                      model: {
                        value: _vm.trainingActivityListForm.mostNum,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.trainingActivityListForm,
                            "mostNum",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "trainingActivityListForm.mostNum"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("人")])],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "报名时间：",
                    "label-width": "125px",
                    prop: "registStartTime"
                  }
                },
                [
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    staticStyle: { width: "198px !important" },
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      type: "datetime",
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "picker-options": _vm.$validate.noBigDate(
                        _vm.$data["trainingActivityListForm"],
                        "registEndTime",
                        true
                      ),
                      placeholder: "开始日期"
                    },
                    model: {
                      value: _vm.trainingActivityListForm.registStartTime,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.trainingActivityListForm,
                          "registStartTime",
                          $$v
                        )
                      },
                      expression: "trainingActivityListForm.registStartTime"
                    }
                  })
                ],
                1
              ),
              _c("span", { staticStyle: { "margin-right": "20px" } }, [
                _vm._v("-")
              ]),
              _c(
                "el-form-item",
                { attrs: { prop: "registEndTime" } },
                [
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    staticStyle: { width: "198px !important" },
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      type: "datetime",
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "picker-options": _vm.$validate.noSmallDate(
                        _vm.$data["trainingActivityListForm"],
                        "registStartTime",
                        true
                      ),
                      placeholder: "结束日期"
                    },
                    model: {
                      value: _vm.trainingActivityListForm.registEndTime,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.trainingActivityListForm,
                          "registEndTime",
                          $$v
                        )
                      },
                      expression: "trainingActivityListForm.registEndTime"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "线上课程有效期：",
                    "label-width": "125px",
                    prop: "courseStartTime"
                  }
                },
                [
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    staticStyle: { width: "198px !important" },
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      type: "datetime",
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "picker-options": _vm.$validate.noBigDate(
                        _vm.$data["trainingActivityListForm"],
                        "courseEndTime",
                        true
                      ),
                      placeholder: "开始日期"
                    },
                    model: {
                      value: _vm.trainingActivityListForm.courseStartTime,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.trainingActivityListForm,
                          "courseStartTime",
                          $$v
                        )
                      },
                      expression: "trainingActivityListForm.courseStartTime"
                    }
                  })
                ],
                1
              ),
              _c("span", { staticStyle: { "margin-right": "20px" } }, [
                _vm._v("-")
              ]),
              _c(
                "el-form-item",
                { attrs: { prop: "courseEndTime" } },
                [
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    staticStyle: { width: "198px !important" },
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      type: "datetime",
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "picker-options": _vm.$validate.noSmallDate(
                        _vm.$data["trainingActivityListForm"],
                        "courseStartTime",
                        true
                      ),
                      placeholder: "结束日期"
                    },
                    model: {
                      value: _vm.trainingActivityListForm.courseEndTime,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.trainingActivityListForm,
                          "courseEndTime",
                          $$v
                        )
                      },
                      expression: "trainingActivityListForm.courseEndTime"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.ifShowOff
            ? _c(
                "div",
                { staticClass: "edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "线下培训时间：",
                        "label-width": "125px",
                        prop: "offLineStartTime"
                      }
                    },
                    [
                      _c("el-date-picker", {
                        staticClass: "zwx-date-picker",
                        staticStyle: { width: "198px !important" },
                        attrs: {
                          "popper-class": "zwx-date-picker-popper",
                          type: "datetime",
                          format: "yyyy-MM-dd HH:mm:ss",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "picker-options": _vm.$validate.noBigDate(
                            _vm.$data["trainingActivityListForm"],
                            "offLineEndTime",
                            true
                          ),
                          placeholder: "开始日期"
                        },
                        model: {
                          value: _vm.trainingActivityListForm.offLineStartTime,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.trainingActivityListForm,
                              "offLineStartTime",
                              $$v
                            )
                          },
                          expression:
                            "trainingActivityListForm.offLineStartTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c("span", { staticStyle: { "margin-right": "20px" } }, [
                    _vm._v("-")
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "offLineEndTime" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "zwx-date-picker",
                        staticStyle: { width: "198px !important" },
                        attrs: {
                          "popper-class": "zwx-date-picker-popper",
                          type: "datetime",
                          format: "yyyy-MM-dd HH:mm:ss",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "picker-options": _vm.$validate.noSmallDate(
                            _vm.$data["trainingActivityListForm"],
                            "offLineStartTime",
                            true
                          ),
                          placeholder: "结束日期"
                        },
                        model: {
                          value: _vm.trainingActivityListForm.offLineEndTime,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.trainingActivityListForm,
                              "offLineEndTime",
                              $$v
                            )
                          },
                          expression: "trainingActivityListForm.offLineEndTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.ifShowOff
            ? _c(
                "div",
                { staticClass: "edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "125px",
                        label: "线下培训地点：",
                        prop: "offLineTrainingAddr"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input",
                        staticStyle: { width: "400px !important" },
                        attrs: {
                          placeholder: "请输入线下培训地点",
                          clearable: "",
                          maxlength: "50"
                        },
                        on: { input: _vm.offLineTrainingAddrInput },
                        model: {
                          value:
                            _vm.trainingActivityListForm.offLineTrainingAddr,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.trainingActivityListForm,
                              "offLineTrainingAddr",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression:
                            "trainingActivityListForm.offLineTrainingAddr"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "125px",
                    label: "是否推荐展示：",
                    prop: "recommendDisplay"
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.trainingActivityListForm.recommendDisplay,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.trainingActivityListForm,
                            "recommendDisplay",
                            $$v
                          )
                        },
                        expression: "trainingActivityListForm.recommendDisplay"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: true } },
                        [_vm._v("是")]
                      ),
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: false } },
                        [_vm._v("否")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.unitNature == 10180003
            ? _c(
                "div",
                { staticClass: "edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "125px",
                        label: "注意事项：",
                        prop: "mattersAttention"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-textarea",
                        staticStyle: { width: "640px !important" },
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 5, maxRows: 8 },
                          maxlength: "1000",
                          placeholder: "请输入",
                          clearable: ""
                        },
                        model: {
                          value: _vm.trainingActivityListForm.mattersAttention,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.trainingActivityListForm,
                              "mattersAttention",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression:
                            "trainingActivityListForm.mattersAttention"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.unitNature == 10180003
            ? _c(
                "div",
                { staticClass: "edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "125px",
                        label: "备注内容：",
                        prop: "remark"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-textarea",
                        staticStyle: { width: "640px !important" },
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 5, maxRows: 8 },
                          maxlength: "1000",
                          placeholder: "请输入",
                          clearable: ""
                        },
                        model: {
                          value: _vm.trainingActivityListForm.remark,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.trainingActivityListForm,
                              "remark",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "trainingActivityListForm.remark"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.unitNature == 10180003
            ? _c(
                "div",
                { staticClass: "edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "125px",
                        label: "附件：",
                        prop: "fileName"
                      }
                    },
                    [
                      _c(
                        "file-upload",
                        {
                          ref: "coverPictureRef",
                          attrs: {
                            accept: ".png,.jpeg,.jpg,.JPG,.JPEG,.PNG,.pdf",
                            size: 50 * 1024 * 1024,
                            sizeInfo: "50M"
                          },
                          on: {
                            showFileList: _vm.picFileShowFileList,
                            success: _vm.picFileUploadSuccess
                          }
                        },
                        [
                          _vm.$zwxBase.verifyIsBlank(
                            _vm.trainingActivityListForm.annexList
                          )
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "zwx-button zwx-button-text-26",
                                  attrs: { icon: "el-icon-upload2" }
                                },
                                [_vm._v("上传")]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.isFileShow
                        ? _c(
                            "div",
                            { staticClass: "show-file" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "zwx-button zwx-button-text-26",
                                  on: {
                                    click: function($event) {
                                      return _vm.openFilePreview(
                                        _vm.trainingActivityListForm.annexList,
                                        0
                                      )
                                    }
                                  }
                                },
                                [_vm._v("查看")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "zwx-button zwx-button-text-26",
                                  attrs: { type: "danger" },
                                  on: {
                                    click: function($event) {
                                      return _vm.deletionPicFile(0)
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "base-headline",
            { staticClass: "courseExpline", attrs: { title: "课程配置" } },
            [
              _vm.trainingActivityListForm.enrollSum == 0
                ? _c(
                    "el-button",
                    {
                      staticClass: "zwx-button zwx-button-24",
                      attrs: { icon: "el-icon-plus" },
                      on: { click: _vm.addCourseExpList }
                    },
                    [_vm._v("添加")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticClass: "zwx-table",
              attrs: {
                "row-key": "rid",
                data: _vm.trainingActivityListForm.activityCourseExpList,
                border: "",
                stripe: "",
                "tooltip-effect": "light"
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "traineeType",
                  label: "学员类型",
                  width: "220",
                  "header-align": "center",
                  align: "left"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop:
                                "activityCourseExpList." +
                                scope.$index +
                                ".traineeType",
                              rules:
                                _vm.trainingActivityListFormRule.traineeType,
                              readonly: ""
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "zwx-select",
                                attrs: {
                                  placeholder: "请选择",
                                  "popper-class": "zwx-select-popper"
                                },
                                on: {
                                  change: val =>
                                    _vm.traineeTypeChange(val, scope.row)
                                },
                                model: {
                                  value: scope.row.traineeType,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "traineeType", $$v)
                                  },
                                  expression: "scope.row.traineeType"
                                }
                              },
                              _vm._l(
                                _vm.trainingActivityListFormExp
                                  .traineeTypeOptions,
                                function(item) {
                                  return _c("el-option", {
                                    key: item.codeNo,
                                    attrs: {
                                      label: item.codeName,
                                      value: item.codeNo
                                    }
                                  })
                                }
                              ),
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "trainingType",
                  label: "培训类型",
                  width: "190",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop:
                                "activityCourseExpList." +
                                scope.$index +
                                ".trainingType",
                              rules:
                                _vm.trainingActivityListFormRule.trainingType
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "zwx-select",
                                attrs: {
                                  placeholder: "请选择",
                                  "popper-class": "zwx-select-popper"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.trainingTypeChange(
                                      $event,
                                      scope.row,
                                      scope.$index
                                    )
                                  }
                                },
                                model: {
                                  value: scope.row.trainingType,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "trainingType", $$v)
                                  },
                                  expression: "scope.row.trainingType"
                                }
                              },
                              _vm._l(scope.row.trainingTypeOptions, function(
                                item
                              ) {
                                return _c("el-option", {
                                  key: item.codeNo,
                                  attrs: {
                                    label: item.codeName,
                                    value: item.codeNo
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "courseUid",
                  label: "课程选择",
                  "min-width": "150",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop:
                                "activityCourseExpList." +
                                scope.$index +
                                ".courseUid",
                              rules: _vm.trainingActivityListFormRule.courseUid
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "zwx-select",
                                attrs: {
                                  placeholder: "请选择",
                                  "popper-class": "zwx-select-popper"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.courseUidChange($event, scope)
                                  }
                                },
                                model: {
                                  value: scope.row.courseName,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "courseName", $$v)
                                  },
                                  expression: "scope.row.courseName"
                                }
                              },
                              _vm._l(scope.row.trainingCourseExpList, function(
                                item
                              ) {
                                return _c("el-option", {
                                  key: item.uuid,
                                  attrs: {
                                    label: item.courseName,
                                    value: {
                                      value: item.uuid,
                                      label: item.courseName,
                                      onlineTotalClassHour:
                                        item.onlineTotalClassHour,
                                      offLineTotalClassHour:
                                        item.offLineTotalClassHour
                                    }
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "onlineTotalClassHour",
                  label: "线上课程学时",
                  width: "120",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _vm.ifShowOff
                ? _c("el-table-column", {
                    attrs: {
                      prop: "offLineTotalClassHour",
                      label: "线下培训学时",
                      width: "120",
                      "header-align": "center",
                      align: "center"
                    }
                  })
                : _vm._e(),
              !_vm.config.ifOnlineCourses && _vm.unitNature != "10180003"
                ? _c("el-table-column", {
                    attrs: {
                      prop: "expiryDate",
                      label: "报名价格（元）",
                      width: "150",
                      "header-align": "center",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.registType == 1
                                ? _c("span", [_vm._v("-")])
                                : _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "activityCourseExpList." +
                                          scope.$index +
                                          ".registPrice",
                                        rules:
                                          _vm.trainingActivityListFormRule
                                            .registPrice
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "zwx-input",
                                        staticStyle: {
                                          width: "130 !important"
                                        },
                                        attrs: {
                                          placeholder: "请输入",
                                          clearable: ""
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.$validate.number(
                                              _vm.$data.trainingActivityListForm
                                                .activityCourseExpList[
                                                scope.$index
                                              ],
                                              "registPrice",
                                              8,
                                              2,
                                              0
                                            )
                                          }
                                        },
                                        model: {
                                          value: scope.row.registPrice,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "registPrice",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "scope.row.registPrice"
                                        }
                                      })
                                    ],
                                    1
                                  )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1114731815
                    )
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: {
                  prop: "registType",
                  label: "报名类型",
                  width: "180",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop:
                                "activityCourseExpList." +
                                scope.$index +
                                ".registType",
                              rules: _vm.trainingActivityListFormRule.registType
                            }
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                staticClass: "zwx-radio-group",
                                model: {
                                  value: scope.row.registType,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "registType", $$v)
                                  },
                                  expression: "scope.row.registType"
                                }
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    staticClass: "zwx-radio",
                                    attrs: { label: 1 }
                                  },
                                  [_vm._v("非公开")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    staticClass: "zwx-radio",
                                    attrs: { label: 2 }
                                  },
                                  [_vm._v("公开")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: "操作",
                  "min-width": "100",
                  "header-align": "left",
                  align: "left"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm.trainingActivityListForm.enrollSum == 0
                          ? _c(
                              "el-button",
                              {
                                staticClass: "zwx-button zwx-button-text-26",
                                attrs: { type: "danger" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteList(scope.$index)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "fixed-footer",
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submit()
                }
              }
            },
            [_vm._v("保存")]
          )
        ],
        1
      ),
      _c(
        "base-dialog",
        {
          ref: "coursewareDialogRef",
          staticClass: "courseware-dialog",
          attrs: {
            title: "选择课程",
            pagination: true,
            total: _vm.trainingActivityListFormExp.materialTotal
          },
          on: {
            determine: _vm.coursewareDialogSubmit,
            cancel: _vm.coursewareDialogCancel,
            close: _vm.coursewareDialogClose,
            currentPage: _vm.coursewareDialogCurrent
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "trainingActivityListFormExp",
              staticClass: "zwx-form",
              staticStyle: { height: "50px !important" },
              attrs: { model: _vm.trainingActivityListFormExp }
            },
            [
              _c(
                "div",
                { staticClass: "edit-form" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "课程名称：", "label-width": "70px" } },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input cc-input",
                        staticStyle: {
                          cursor: "pointer",
                          "min-width": "30px !important",
                          width: "295px !important"
                        },
                        attrs: {
                          placeholder: "输入查询",
                          maxlength: "200",
                          clearable: ""
                        },
                        model: {
                          value:
                            _vm.trainingActivityListFormExp.materialNameLike,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.trainingActivityListFormExp,
                              "materialNameLike",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression:
                            "trainingActivityListFormExp.materialNameLike"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "zwx-button zwx-button-icontext-28",
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: {
                        click: function($event) {
                          return _vm.getTeachingMaterialList(
                            1,
                            _vm.trainingActivityListFormExp.coursewareIndex
                          )
                        }
                      }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "el-table",
            {
              ref: "teachingMaterialTable",
              staticClass: "zwx-table",
              attrs: {
                "row-key": "uuid",
                data: _vm.trainingActivityListFormExp.teachingMaterialList,
                stripe: "",
                "tooltip-effect": "light",
                "max-height": "310px"
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "选择",
                  width: "80",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-radio", {
                          staticClass: "zwx-radio-course",
                          staticStyle: {
                            "margin-right": "unset !important",
                            width: "15px"
                          },
                          attrs: { label: scope.row.uuid },
                          on: {
                            change: function($event) {
                              return _vm.handleCoursewareSelectionChange(
                                scope.row
                              )
                            }
                          },
                          model: {
                            value: _vm.trainingActivityListFormExp.courseUid,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.trainingActivityListFormExp,
                                "courseUid",
                                $$v
                              )
                            },
                            expression: "trainingActivityListFormExp.courseUid"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "courseName",
                  label: "课程名称",
                  "min-width": "550",
                  "header-align": "center",
                  align: "left",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "totalClassHour",
                  label: "学时要求",
                  "header-align": "center",
                  align: "center",
                  fixed: "right"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("cover-upload-dialog", {
        ref: "coverUploadDialog",
        attrs: {
          "upload-folder": "saas/" + _vm.config.domainPrefix + "/activity"
        },
        on: { confirm: _vm.onCoverUpload }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <!-- v-show="annexList1 && annexList1.length == 0" -->
    <file-upload
      ref="fileUploadRef1"
      @showFileList="clickPositive1"
      @success="clickPositiveSuccess1"
      @error="clickError()"
      :size="20 * 1024 * 1024"
      sizeInfo="20M"
      :accept="accept.map(v => `.${v}`).join(',')"
      :style="{
        position: 'relative',
      }"
    >
      <!--  
      <div class="btn-css">
        <i class="el-icon-plus file-uploader-icon"></i>
      </div>
      -->
      <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-upload2">{{ value ? '重新选择' : '选择' }}</el-button>
    </file-upload>
    <!-- <span v-show="annexList1 && annexList1.length == 0" style="color: #999999;font-size: 14px;line-height: 16px;font-weight: 400;margin-left:8px">请先下载证书模板，按要求正确填写参数后上传</span> -->
    <!-- <span v-show="annexList1 && annexList1.length == 0" style="color: #999999;font-size: 16px;line-height: 16px;font-weight: 400;margin-left:8px">注意：支持10M以内的pdf、PDF。</span> -->
    <!--
    <div v-if="annexList1 && annexList1.length > 0">
      <div v-for="(item, index) in annexList1" :key="item.fileName" @mouseenter="mouseenter(index, 1)" @mouseleave="mouseleave(index, 1)" style="line-height: 40px;width: fit-content;">
        <span>
          <i :class="item.fileIconShow" style="color:#316CF5! important"></i>
          <a style="color:#194DFF !important;cursor:pointer;" @click="openFilePreview(annexList1, index)">
            {{ item.fileName }}
          </a>
          <span class="el-upload-list__item check" :style="item.check ? 'margin-left:5px;display:none;' : 'margin-left:5px;cursor:pointer'">
            <i class="el-icon-upload-success el-icon-circle-check"></i>
          </span>
          <span class="check" :style="item.check ? 'margin-left:5px;cursor:pointer' : 'margin-left:5px;display:none;'">
            <i class="el-icon-close" @click="deletionPositive(index, 1)"></i>
          </span>
        </span>
      </div>
    </div>
    -->
    <br />
    <el-image
      v-if="value"
      :src="`${file}${value}`"
      fit="contain"
      :preview-src-list="[`${file}${value}`]"
      :style="{
        margin: '10px 0 0',
        maxWidth: '140px',
      }"
    />

    <file-preview ref="filePreview" />
    <!-- 成功弹框 -->
    <div class="success-dialog">
      <el-dialog :visible.sync="successDialog" :close-on-press-escape="false" :close-on-click-modal="false" :show-close="false">
        <div style="display:flex;align-items:center;justify-content: center;flex-direction: column;height:100%;">
          <div style="margin-top:56px" class="success-ico"></div>
          <div style="font-size: 16px;color: #3d3f44;line-height: 16px;margin:24px 0">{{ successTitle }}</div>
          <div style="font-size: 16px;color: #bdbdbd;line-height: 24px;margin-bottom:50px">{{ successText }}</div>
          <div class="form-box-button">
            <el-button type="primary" @click="toHome">{{ personalMemberInfo }}</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    uploadFolder: {
      type: String,
      required: true,
    },
    accept: {
      type: Array,
      default: () => ['png', 'jpg', 'jpeg'],
    },
  },
  data() {
    return {
      api: this.$store.state.api,
      users: this.$store.state.users,
      file: this.$store.state.file,
      folder: '/membershipNotice',
      uuid: this.$route.query.uuid,
      successDialog: false,
      successTitle: '',
      successText: '',
      personalMemberInfo: '去登录',
      msForm: {
        targetPopulation: undefined,
        mobileTel: '',
        email: '',
        password: '',
        confirmPassword: '',
        indateType: '1',
        name: '',
        sex: '',
        birthday: '',
        cardType: '',
        cardNo: '',
        politicalLandscape: '',
        education: '',
        zoneCode: '',
        linkAddr: '',
        zipCode: '',
        jobTitle: '',
        jobTitlePath: '',
        academic: '',
        manFileExpList: [],
      },

      annexList1: [],
      annexList2: [],
      newView: true,
      confirmView: true,
      selectIndex: 1,
      zoneCode12From: '320000000000',
      cardTypeOptions: [],
      politicalLandscapeOptions: [],
      educationOptions: [],
      jobTitleOptions: [],
      top1: 0,
      top2: 0,
      top3: 0,
      top4: 0,
      tdMemberManExp: {},
    }
  },
  watch: {
    value: {
      handler(v, oldV) {
        if (v && !oldV && !this.annexList1.length) {
          this.annexList1.push({
            check: false,
            fileType: 1,
            // fileIconShow: this.$zwxBase.dealfilePrefix(v.annexName),
            fileName: v,
            filePath: v,
          })
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.selectIndex = this.uuid ? 2 : 1
  },
  mounted() {
    this.msForm.zoneCode = '320000000000'
  },
  methods: {
    toHome() {
      this.$router.replace({ name: 'home' })
    },
    /**
     * 附件上传
     * */
    generateFilesUid1() {
      this.$emit('loading', true)
      let data = {
        folder: this.uploadFolder,
      }
      this.$system.get(this.api + '/systematic/tools/getAnnexUploadUid-1', data, true, true, this.generateSuccess1, this.error)
    },
    generateSuccess1(data) {
      if (data.type === '00') {
        this.$refs.fileUploadRef1.submit(data.annexUploadUid)
      } else {
        this.$emit('loading', false)
        this.$system.notify('错误', data.mess, 'error')
      }
    },

    clickPositive1(showList) {
      showList.forEach(item => {
        // let flag = this.annexList1.some(e => e.fileName == item)
        // if (flag) {
        //   return
        // }
        this.annexList1.push({
          fileName: item,
          // fileIconShow: this.$zwxBase.dealfilePrefix(item),
          check: false,
        })
      })

      this.generateFilesUid1()
    },
    clickPositiveSuccess1(fileList) {
      this.$emit('loading', false)
      if (this.$zwxBase.verifyIsNotBlank(this.annexList1) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in this.annexList1) {
          for (let j in fileList) {
            if (this.annexList1[i].fileName === fileList[j].fileName) {
              this.annexList1[i].filePath = fileList[j].filePath
              this.annexList1[i].fileType = 1
            }
          }
        }
        this.msForm.jobTitlePath = this.annexList1[this.annexList1.length - 1].filePath
        this.$forceUpdate()
        this.$emit('change', this.annexList1[this.annexList1.length - 1].filePath)
      }
    },
    clickError1() {},
    generateFilesUid2() {
      this.$emit('loading', true)
      let data = {
        folder: this.folder,
      }
      this.$system.get(this.api + '/systematic/tools/getAnnexUploadUid-0', data, true, true, this.generateSuccess2, this.error)
    },
    generateSuccess2(data) {
      if (data.type === '00') {
        this.$refs.fileUploadRef2.submit(data.annexUploadUid)
      } else {
        this.$emit('loading', false)
        this.$system.notify('错误', data.mess, 'error')
      }
    },
    error(data) {
      this.$emit('loading', false)
      this.$system.notify('错误', data.mess, 'error')
    },
    clickPositive2(showList) {
      showList.forEach(item => {
        let flag = this.annexList2.some(e => e.fileName == item)
        if (flag) {
          return
        }
        this.annexList2.push({
          fileName: item,
          fileIconShow: this.$zwxBase.dealfilePrefix(item),
          check: false,
        })
      })
      this.generateFilesUid2()
    },
    clickPositiveSuccess2(fileList) {
      this.$emit('loading', false)
      if (this.$zwxBase.verifyIsNotBlank(this.annexList2) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in this.annexList2) {
          for (let j in fileList) {
            if (this.annexList2[i].fileName === fileList[j].fileName) {
              this.annexList2[i].filePath = fileList[j].filePath
              this.annexList2[i].fileType = 1
            }
          }
        }
      }
    },
    clickError2() {},
    mouseenter(index, num) {
      if (num == 1) {
        this.annexList1[index].check = true
      } else {
        this.annexList2[index].check = true
      }
      this.$forceUpdate()
    },
    mouseleave(index, num) {
      if (num == 1) {
        this.annexList1[index].check = false
      } else {
        this.annexList2[index].check = false
      }
      this.$forceUpdate()
    },
    // 删除文件
    deletionPositive(index, num) {
      if (num == 1) {
        let fileName = this.annexList1[index].fileName
        let filePath = this.annexList1[index].filePath
        this.annexList1.splice(index, 1)
        this.$forceUpdate()
        if (this.$zwxBase.verifyIsBlank(filePath)) {
          this.$refs.fileUploadRef1.deleteIndex(index)
        }
        let fileList = this.$refs.fileUploadRef1.showFileList || []
        let count = 0
        fileList.forEach(item => {
          if (fileName === item) {
            count++
          }
        })
        for (let i = 0; i < count; i++) {
          for (let j in fileList) {
            if (fileName === fileList[j]) {
              this.$refs.fileUploadRef1.deleteIndex(j)
              fileList = this.$refs.fileUploadRef1.showFileList || []
              continue
            }
          }
        }
        this.msForm.jobTitlePath = ''
        this.$emit('change', '')
      } else {
        let fileName = this.annexList2[index].fileName
        let filePath = this.annexList2[index].filePath
        this.annexList2.splice(index, 1)
        this.$forceUpdate()
        if (this.$zwxBase.verifyIsBlank(filePath)) {
          this.$refs.fileUploadRef2.deleteIndex(index)
        }
        let fileList = this.$refs.fileUploadRef2.showFileList || []
        let count = 0
        fileList.forEach(item => {
          if (fileName === item) {
            count++
          }
        })
        for (let i = 0; i < count; i++) {
          for (let j in fileList) {
            if (fileName === fileList[j]) {
              this.$refs.fileUploadRef2.deleteIndex(j)
              fileList = this.$refs.fileUploadRef2.showFileList || []
              continue
            }
          }
        }
      }
    },
    openFilePreview(fileList, index) {
      // top.postMessage(
      //   {
      //     handlerType: 'previewFile',
      //     params: {
      //       fileList: fileList,
      //       index: index,
      //     },
      //   },
      //   '*'
      // )
      let previewFileList = []
      if (!this.$zwxBase.verifyIsBlank(fileList)) {
        fileList.forEach(file => {
          let item = {
            fileName: file.fileName,
            filePath: file.filePath,
          }
          previewFileList.push(item)
        })
        this.$nextTick(function() {
          this.$refs.filePreview.initPreviewFile(previewFileList, index)
        })
      }
    },
    passSeeNew() {
      this.newView = !this.newView
    },
    passSeeConfirm() {
      this.confirmView = !this.confirmView
    },
    selectInfo(index) {
      this.selectIndex = index
      this.$refs.scrollbar.$el.querySelectorAll('.el-scrollbar__wrap')[0].scrollTo(0, this['top' + index])
    },
    getMinIndex(list, scrollTop, scrollMaxHeight) {
      if (scrollTop >= scrollMaxHeight) {
        let indexList = []
        for (let i = 0; i < list.length; i++) {
          if (list[i] >= scrollMaxHeight) indexList.push(i + 1)
        }
        indexList[0] = indexList[0] || list.length
        return indexList.includes(this.selectIndex) ? this.selectIndex : indexList[0]
      }
      list = list.map(v => Math.abs(scrollTop - v))
      for (let i = 0; i < list.length; i++) {
        if (Math.min(...list) === list[i]) {
          return i + 1
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.success-dialog {
  .el-dialog {
    width: 600px !important;
    min-width: 600px !important;
    height: 400px;
    background: #ffffff !important;
    border-radius: 8px !important;
    box-shadow: 0px 8px 24px 0px rgba(143, 170, 200, 0.22) !important;
    .el-dialog__header {
      display: none;
    }
  }
  .success-ico {
    width: 90px;
    height: 90px;
    // background-image: url('../../assets/images/bgImg/img_succeed.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .form-box-button {
    width: 264px;

    .el-button {
      font-family: 'Harmony Light';
      width: 100%;
      height: 60px !important;
      background: #5e81ff !important;
      border-radius: 4px;
      letter-spacing: 2px;
      font-size: 20px;
    }

    .el-button:hover {
      background: #0059ff !important;
    }

    .el-button:active {
      background: #0059ff !important;
    }
  }
}

.zwx-button-32 {
  padding: 0 111px !important;
  height: 44px !important;
}
.btn-css {
  width: 86px;
  height: 86px;
  background: #ffffff;
  border: 1px dashed #d7dae0;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  &:hover {
    border-color: #409eff;
  }
}
.personalIndex {
  padding: 96px 0;
  height: 2162px;
  // background: url('../../assets/images/bgImg/bg_middle2.png') no-repeat;
  background-size: 100% 118%;
  background-position: 0px -183px;
  position: relative;
  .directory {
    position: fixed;
    top: 304px;
    left: calc((100vw - 1450px) / 2);
    width: fit-content;
    li {
      height: 48px;
      border-left: 1px solid rgba(85, 116, 223, 0.2);
      position: relative;
      a {
        width: 100px;
        position: absolute;
        top: -10px;
        left: 15px;
        color: rgba(61, 63, 68, 0.6);
        font-size: 16px;
      }
      &::before {
        content: '';
        width: 8px;
        height: 8px;
        background: #cbd5f3;
        border-radius: 50%;
        position: absolute;
        top: -4px;
        left: -4px;
      }
      &:last-child {
        border: none;
      }
    }
    .selectli {
      // border-color: #5574df;
      &::before {
        content: '';
        width: 12px;
        height: 12px;
        background: #ffffff;
        border: 2px solid #5574df;
        border-radius: 50%;
        position: absolute;
        top: -8px;
        left: -8px;
      }
      a {
        color: #5574df;
      }
    }
  }
}
.body {
  padding: 54px 0;
  margin: 0 auto;
  width: 1200px;
  background: #fff;
  position: relative;
  .title {
    padding-bottom: 20px;
    font-size: 32px;
    color: #3b3b3b;
    text-align: center;
  }
  .info {
    margin-top: 30px;
    padding-left: 152px;
    /deep/ .el-form-item__label {
      font-size: 16px;
      color: #3d3f44;
    }
    /deep/ .infogroup {
      min-width: fit-content !important;
    }
    &-title {
      padding-bottom: 30px;
      font-size: 24px;
      color: #3b3b3b;
    }
  }
  .infoInput {
    // height: 46px;
    /deep/ .el-input__inner {
      height: 46px !important;
      width: 416px;
      border: 1px solid #a8bbcf !important;
      border-radius: 2px !important;
      font-size: 16px !important;
    }
    /deep/ .el-input {
      width: 416px !important;
    }

    /deep/.el-textarea__inner {
      width: 416px !important;
    }
  }
}
</style>

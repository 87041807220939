var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "improve-information-dialog" },
    [
      _c(
        "base-dialog",
        {
          ref: "registerValidDialog",
          staticClass: "improve-dialog",
          staticStyle: { width: "480px" },
          attrs: { title: "企业已被注册", determineText: "账号申诉" },
          on: {
            determine: _vm.improveDialogSubmit,
            cancel: _vm.improveDialogCancel,
            close: _vm.improveDialogClose
          }
        },
        [
          _c("div", { staticClass: "body" }, [
            _c(
              "div",
              {
                staticStyle: { "margin-bottom": "20px", "text-align": "center" }
              },
              [_c("i", { staticClass: "el-icon-warning" })]
            ),
            _c("p", [
              _vm._v(" 您申请注册的企业 "),
              _c("span", [_vm._v(_vm._s(_vm.unitName))]),
              _vm._v(" 已被手机尾号 "),
              _c("span", [_vm._v(_vm._s(_vm.endNumber))]),
              _vm._v(" 的用户注册，请确认。 ")
            ]),
            _c("p", [
              _vm._v(" 若确认被他人注册，您可以在该页面或登录页面点击 "),
              _c("span", [_vm._v("账号申诉")]),
              _vm._v(" 进行账号找回。 ")
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }